// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-accessories-jsx": () => import("./../../../src/pages/accessories.jsx" /* webpackChunkName: "component---src-pages-accessories-jsx" */),
  "component---src-pages-collages-jsx": () => import("./../../../src/pages/collages.jsx" /* webpackChunkName: "component---src-pages-collages-jsx" */),
  "component---src-pages-concept-jsx": () => import("./../../../src/pages/concept.jsx" /* webpackChunkName: "component---src-pages-concept-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-ideation-images-jsx": () => import("./../../../src/pages/IdeationImages.jsx" /* webpackChunkName: "component---src-pages-ideation-images-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-prints-jsx": () => import("./../../../src/pages/prints.jsx" /* webpackChunkName: "component---src-pages-prints-jsx" */),
  "component---src-pages-technical-design-jsx": () => import("./../../../src/pages/technical-design.jsx" /* webpackChunkName: "component---src-pages-technical-design-jsx" */),
  "component---src-pages-technical-development-jsx": () => import("./../../../src/pages/technical-development.jsx" /* webpackChunkName: "component---src-pages-technical-development-jsx" */),
  "component---src-pages-women-ideation-jsx": () => import("./../../../src/pages/women-ideation.jsx" /* webpackChunkName: "component---src-pages-women-ideation-jsx" */),
  "component---src-pages-women-jsx": () => import("./../../../src/pages/women.jsx" /* webpackChunkName: "component---src-pages-women-jsx" */)
}

